import styles from './locator-tile.module.scss';

// components
import { VideoConsent } from './video';
import { StyledWrapper } from '../showroom-locator-teaser/showroom-locator-teaser.styles';
import { SearchContextProvider } from 'components/ContentElementsGdds/locator/search';

// utils
import { useUCConsent } from 'features/cookie-consent';
import { Log } from 'services/log';
import { useIsPreview } from 'utils/hooks/useIsPreview';
import { useLocatorConfiguration } from 'components/ContentElementsGdds/locator/utils/use-locator-configuration';
import { classNameBuilder } from 'utils/classNameBuilder';
import { ShowroomLocatorTileContent } from './showroom-locator-tile-content';
import { useIsMobile } from 'components/App/SizeProvider';
import { BackgroundImage } from 'components/BackgroundImage/BackgroundImage';
import { responsiveImage } from 'utils/responsiveImage';

type ShowroomLocatorTileProps = {
  subheadline: string;
  headline: string;
  colorVariant: string;
  text: string;
  from: string;
  index: number;
  previewId: string;
  imageObject: ImageObject;
  imageObjectMobile: ImageObjectMobile;
  pictureAlt?: string;
};

export function ShowroomLocatorTile({
  subheadline,
  headline,
  colorVariant,
  text,
  from,
  index,
  previewId,
  imageObject,
  imageObjectMobile,
  pictureAlt,
}: Readonly<ShowroomLocatorTileProps>) {
  const consent = useUCConsent();
  const isPreview = useIsPreview();
  const locatorConfiguration = useLocatorConfiguration();
  const locatorUrl = locatorConfiguration.url;
  const isMobile = useIsMobile();
  const image = responsiveImage(isMobile, imageObjectMobile, imageObject) as ImageObject;
  const orientationClass = image?.url ? 'c-tile-homepage__width-2' : '';

  const showConsent = consent.Comfort === false && locatorConfiguration !== undefined;

  if (!locatorUrl) {
    if (isPreview) {
      return (
        <StyledWrapper>
          <p>locator url is missing!</p>
        </StyledWrapper>
      );
    }

    Log.error('locatorUrl is missing!');
    return null;
  }

  return (
    <SearchContextProvider locatorUrl={locatorUrl}>
      <div
        className={`c-tile-homepage c-tile-homepage--${colorVariant} ${orientationClass} ${styles.locatorTile} ${styles.locatorOne}`}
      >
        <div
          data-preview-id={from !== 'homepage' ? `#${index}` : previewId}
          className={classNameBuilder('c-tile-content', styles.withOverflow)}
        >
          <div style={{ width: '100%', height: '100%' }}>
            {showConsent && <VideoConsent />}
            {!showConsent && (
              <ShowroomLocatorTileContent
                headline={headline}
                subheadline={subheadline}
                text={text}
              />
            )}
          </div>

          {image?.url && (
            <BackgroundImage
              uid={image.uid}
              previewId={image.previewId}
              format2x={image.format2x}
              format3x={image.format3x}
              format={image.format}
              isRemoteMedia={image.remote}
              imagePath={image.url}
              retinaSrc={isMobile ? imageObjectMobile : imageObject}
              title={pictureAlt}
              className="c-tile-content__image"
            />
          )}
        </div>
      </div>
    </SearchContextProvider>
  );
}
