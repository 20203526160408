import { useState } from 'react';

// components
import TileContent from './TileContent';
import { CmsLink } from 'components/Link/CmsLink';
import VideoPlayButton from 'components/Video/VideoPlayButton';
import VideoLightbox from 'components/LightBox/VideoLightbox';
import { BackgroundImage } from 'components/BackgroundImage/BackgroundImage';

// utils
import { clickTileLink } from '../trackingActions';
import { responsiveImage } from 'utils/responsiveImage';
import { useTracking } from 'utils/hooks/useTracking';
import { useUCConsent, VideoConsent } from 'features/cookie-consent';
import { useIsMobile } from 'components/App/SizeProvider';

interface VideoTileProps {
  link: any;
  imageObject: any;
  imageObjectMobile: any;
  pictureAlt: string | null;
  video: any;
  type: string;
}

function VideoTile(props) {
  const {
    link = null,
    imageObject = null,
    imageObjectMobile = null,
    pictureAlt = null,
    video = null,
  }: Readonly<VideoTileProps> = props;
  const isMobile = useIsMobile();
  const [lightBoxOpen, setLightBoxOpen] = useState(false);
  const ucConsent = useUCConsent();
  const image = responsiveImage(isMobile, imageObjectMobile, imageObject);

  const handleCloseLightBox = () => {
    setLightBoxOpen(false);
  };

  const handleOpenLightBox = (e) => {
    e.preventDefault();
    setLightBoxOpen(true);
  };

  const renderLightbox = () => {
    const { videoId, videoHeadline, videoDescription } = video;

    return (
      <VideoLightbox
        isModal
        videoId={videoId}
        title={videoHeadline}
        text={videoDescription}
        onClose={handleCloseLightBox}
      />
    );
  };

  const track = useTracking();
  const onClick = () => {
    const toLink = typeof link === 'string' ? link : link.target;
    track.trackEvent(clickTileLink(window.location.href, toLink));
  };

  return (
    <div className="c-tile-content c-tile-video">
      <CmsLink className="c-tile-content__link" link={link} onClick={onClick}>
        <TileContent {...props} />
      </CmsLink>
      <a href="#openVideo" className="c-tile-content__link" onClick={handleOpenLightBox}>
        {image && (
          <BackgroundImage
            uid={image.uid}
            previewId={image.previewId}
            format={image.format}
            isRemoteMedia={image.remote}
            imagePath={image.url}
            format2x={image.format2x}
            format3x={image.format3x}
            retinaSrc={imageObject}
            title={pictureAlt}
            className="c-tile-content__image c-tile-content__image--video"
          >
            {ucConsent.Comfort === true ? <VideoPlayButton /> : <VideoConsent type="mi24" inTile />}
          </BackgroundImage>
        )}
      </a>
      {lightBoxOpen && renderLightbox()}
    </div>
  );
}

export default VideoTile;
