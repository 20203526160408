import { LineClampElement, ScreenBreakpointWidth, TileTypeKeys, tileTypes } from './tileTypes';
import { classNameBuilder } from '../classNameBuilder';

export function getTileLineClampClass(type: TileTypeKeys, variant: LineClampElement) {
  if (!tileTypes[type]) return undefined;

  const tile = tileTypes[type];

  if (!tile.lineClamps) return undefined;

  const size = tile.lineClamps['50percent'];
  if (!size) return undefined;

  const cssClasses = Object.entries(size).map(([key, value]) => {
    const breakpointSize = key as ScreenBreakpointWidth;
    const lineClampAmount = value[variant] as number;

    if (breakpointSize === '0') return `line-clamp-${lineClampAmount}`;

    return `line-clamp-${breakpointSize}-${lineClampAmount}`;
  });

  if (!cssClasses.length) return undefined;

  return classNameBuilder(...cssClasses);
}
