// styles
import styles from './video.module.scss';

// components
import { BUTTON_BACKGROUND, BUTTON_COLORS, BUTTON_TYPES, Button } from 'components/Button/Button';
import { InnerHtml } from 'components/InnerHtml/inner-html';

// utils
import { useLocatorConfiguration } from 'components/ContentElementsGdds/locator/utils/use-locator-configuration';
import { useUsercentrics } from 'features/cookie-consent';

export function VideoConsent() {
  const uc = useUsercentrics();
  const locatorConfiguration = useLocatorConfiguration();

  return (
    <div className={styles.consentContainer}>
      <InnerHtml
        className={styles.text}
        as="div"
        content={locatorConfiguration.consent.consentText}
      />
      <Button
        type={BUTTON_TYPES.DEFAULT}
        color={BUTTON_COLORS.WHITE}
        background={BUTTON_BACKGROUND.PRIMARY}
        onClick={() => {
          uc?.enableSetting(locatorConfiguration.consent.type);
        }}
      >
        {locatorConfiguration.consent.buttonLabel}
      </Button>
    </div>
  );
}
