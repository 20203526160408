import styled from 'styled-components';

export const StyledWrapper = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 3px dashed #999999;
`;

export const Wrapper = styled.div`
  margin-bottom: 4rem;
`;
