import React from 'react';

import OrientationTile from 'components/ContentElements/Teaser/OrientationTile';
import QuadraticTile from 'components/ContentElements/Teaser/QuadraticTile';
import JobsTile from 'components/ContentElements/Teaser/jobs-tile';
import LocatorTile from 'components/ContentElements/Teaser/LocatorTile';
import { ShowroomLocatorTile } from 'components/ContentElements/Teaser/showroom-locator-tile';

export type LineClampElement = 'subheadline' | 'headline' | 'text' | 'cta';

// When adding a breakpoint value here make sure it is handled in _tiles.scss
export type ScreenBreakpointWidth = '0' | '768' | '1024' | '1440';

type LineClampSize = Partial<
  Record<ScreenBreakpointWidth, Partial<Record<LineClampElement, number>>>
>;

export type TileTypes = Record<
  string,
  {
    component: React.FunctionComponent;
    lineClamps?: {
      '50percent': LineClampSize;
      '100percent'?: LineClampSize;
    };
  }
>;

export type TileTypeKeys = keyof typeof tileTypes;

/*
  How does the lineClamps definition work?

  lineClamps: {

    // How much space the tile takes in the grid, 50percent is a regular square tile
    '50percent': {

     // The width numbers define which lineclamp rules will be applied at that screen width
     // These are applied in a mobile first approach, meaning the bigger number will overwrite the previous one
      '0': {

      // Defines how many lines should be visible for the element
      // If an element key is not defined the lines will be unresticed for that breakpoint
      // Example: At width 0 the text of the "subheadline" element is clamped after 1 line
      subheadline: 1, headline: 2, text: 5, cta: 1

      },
    },
  }
*/

export const tileTypes = {
  vertical_tile: {
    component: OrientationTile,
    lineClamps: {
      '50percent': {
        '0': { subheadline: 1, headline: 1, text: 1, cta: 1 },
        '768': { subheadline: 1, headline: 2, text: 3, cta: 1 },
        '1024': { subheadline: 1, headline: 2, text: 5, cta: 1 },
        '1440': { subheadline: 1, headline: 2, text: 5, cta: 1 },
      },
    },
  },
  horizontal_tile: {
    component: OrientationTile,
    lineClamps: {
      '50percent': {
        '0': { subheadline: 1, headline: 1, text: 2, cta: 1 },
        '768': { subheadline: 1, headline: 1, text: 3, cta: 1 },
        '1024': { subheadline: 1, headline: 2, text: 5, cta: 1 },
        '1440': { subheadline: 1, headline: 2, text: 5, cta: 1 },
      },
    },
  },
  quadratic_general_tile: {
    component: QuadraticTile,
    lineClamps: {
      '50percent': {
        '0': { subheadline: 1, headline: 1, text: 1, cta: 1 },
        '768': { subheadline: 1, headline: 1, text: 4, cta: 1 },
        '1024': { subheadline: 1, headline: 2, text: 4, cta: 1 },
      },
    },
  },
  quadratic_download_tile: {
    component: QuadraticTile,
    lineClamps: {
      '50percent': {
        "0": { subheadline: 1, headline: 2, text: 4 },
      },
    },
  },
  quadratic_locator_tile: {
    component: QuadraticTile,
    lineClamps: {
      '50percent': {
        "0": { subheadline: 1, headline: 2, text: 4 },
        "768": { subheadline: 1, headline: 1, text: 4 },
        "1024": { subheadline: 1, headline: 2, text: 4 },
      },
    },
  },
  jobs_tile: {
    component: JobsTile,
    lineClamps: {
      '50percent': {
        "0": { subheadline: 1, headline: 2, text: 4 },
      },
    },
  },
  locator_tile: {
    component: LocatorTile,
    lineClamps: {
      '50percent': {
        '0': { subheadline: 1, headline: 1, text: 1, cta: 1 },
        '768': { subheadline: 1, headline: 1, text: 4, cta: 1 },
        '1024': { subheadline: 1, headline: 2, text: 4, cta: 1 },
        '1440': { subheadline: 1, headline: 2, text: 4, cta: 1 },
      },
    },
  },
  showroom_locator_tile: {
    component: ShowroomLocatorTile,
    lineClamps: {
      '50percent': {
        '0': { subheadline: 1, headline: 1, text: 1 },
        '768': { subheadline: 1, headline: 1, text: 4 },
        '1024': { subheadline: 1, headline: 2, text: 4 },
        '1440': { subheadline: 1, headline: 2, text: 4 },
      },
    },
  },
} satisfies TileTypes;
