import key from 'weak-key';

// components
import LinkTile from './Tile/LinkTile';
import VideoTile from './Tile/VideoTile';
import { ToolIntegration } from '../ToolIntegration/ToolIntegration';
import { previewId } from '../../../utils/preview-id';

interface OrientationTileProps {
  textColor: string;
  subheadline: string;
  headline: string;
  text: string;
  link: any;
  image: string;
  journeyType: string;
  previewId: string | null;
  previewHash: string;
  video: any;
  type: string;
  tool: boolean;
  scriptUrl: string | null;
  styleUrl: string | null;
  identifier: string | null;
  colorVariant: string;
  index: number;
  from: string | null;
}

function OrientationTile(props: OrientationTileProps) {
  const {
    type,
    index,
    previewId: previewIdFromProps = null,
    video = null,
    tool = false,
    scriptUrl = null,
    styleUrl = null,
    identifier = null,
    colorVariant = 'white',
    from = null,
  }: Readonly<OrientationTileProps> = props;
  const orientationClass = type === 'horizontal_tile' ? 'width-2' : 'height-2';
  const colorVariantClass = `c-tile-homepage--${colorVariant}`;

  if (tool) {
    return (
      <div
        {...previewId(from !== 'homepage' ? `#${index}` : previewIdFromProps)}
        key={key({ previewId: previewIdFromProps })}
        className={`c-tile-homepage c-tile-homepage__${orientationClass} ${colorVariantClass}`}
      >
        <div className="c-tile-content">
          <ToolIntegration
            scriptUrl={scriptUrl}
            styleUrl={styleUrl}
            identifier={identifier}
            mode="embedded-home"
          />
        </div>
      </div>
    );
  }

  return (
    <div
      key={key({ previewId: previewIdFromProps })}
      className={`c-tile-homepage c-tile-homepage__${orientationClass} ${colorVariantClass}`}
      data-preview-id={from !== 'homepage' ? `#${index}` : previewIdFromProps}
    >
      {video?.videoId && video.videoId !== '' ? <VideoTile {...props} /> : <LinkTile {...props} />}
    </div>
  );
}

export default OrientationTile;
