// components
import { InnerHtml } from 'components/InnerHtml/inner-html';
import { Image } from 'components/Image/Image';

// utils
import { decodingContent } from 'utils/decodingContent';
import { previewId } from 'utils/preview-id';
import { classNameBuilder } from 'utils/classNameBuilder';
import { getTileLineClampClass } from 'utils/tile/tileUtils';
import { tileTypes } from 'utils/tile/tileTypes';
import {useTracking as useTracking2} from "utils/tracking/track";

interface TileContentProps {
  subheadline: string | null;
  headline: string | null;
  link: any | null;
  text: string | null;
  journeyType: string | null;
  isFullwidth: string;
  type: keyof typeof tileTypes;
}

function TileContent({
  subheadline = null,
  headline = null,
  link = null,
  text = null,
  journeyType = null,
  isFullwidth = '',
  type,
}: Readonly<TileContentProps>) {
  const {trackClick} = useTracking2()

  return (
    <div className="c-tile-content__text">
      {subheadline && (
        <span
          {...previewId('#st_subheadline')}
          className={classNameBuilder('category', getTileLineClampClass(type, 'subheadline'))}
        >
          {decodingContent(subheadline)}
        </span>
      )}
      {headline && (
        <InnerHtml
          as="h3"
          content={headline}
          previewId="#st_headline"
          className={getTileLineClampClass(type, 'headline')}
        />
      )}
      {text && (
        <InnerHtml
          as="p"
          content={text}
          previewId="#st_text"
          className={getTileLineClampClass(type, 'text')}
        />
      )}
      {link && (
        <span className={classNameBuilder('link', getTileLineClampClass(type, 'cta'))} onClick={() => trackClick({click_intent:"more_info", click_element:"tile"})}>
          {decodingContent(link.text)}
        </span>
      )}
      {journeyType && !isFullwidth && (
        <div className="marker">
          <Image src={`/icons/icon-${journeyType}.svg`} alt={journeyType} staticImage />
        </div>
      )}
    </div>
  );
}

export default TileContent;
