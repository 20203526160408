import React from 'react';
import { type TileTypeKeys, tileTypes } from 'utils/tile/tileTypes';

function TileRenderer({ ...data }: { type: TileTypeKeys }) {
  const { type } = data;
  const Tile = tileTypes[type].component;

  return Tile ? <Tile {...(data as any)} /> : null;
}

export default TileRenderer;
